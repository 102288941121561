import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const PseudarthroseDuScaphoide = () => (
  <Layout
    title="Pseudarthrose du scaphoïde : Diagnostic et traitement"
    description="La pseudarthrose du scaphoïde est définie par l'absence de consolidation osseuse dans les 6 mois après une fracture du scaphoïde."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Pseudarthrose du scaphoïde" />
        <MainPart title="Qu'est-ce qu'une pseudarthrose du scaphoïde ?">
          <Typography weight="bold">
            La pseudarthrose du scaphoïde
            {' '}
            <Typography variant="span">
              est définie par l&apos;absence de consolidation osseuse dans les
              6 mois après la survenue d&apos;une fracture du scaphoïde.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les os qui composent la carpe ?">
          <Typography>
            Le carpe est formé de huit os, disposés en deux rangées de quatre os. La première
            rangée s&apos;articule aves les deux os de l&apos;avant-bras
            (radius et cubitus). La deuxième
            rangée s&apos;articule avec la première rangée en haut et avec
            les métacarpes en bas (Figure 1).
          </Typography>
          <Figure
            imageName="figure1-pseudarthroseduscaphoide.jpg"
            caption="Figure 1. Anatomie des os du poignet"
            currentOpTitle="Pseudoarthrose du scaphoïde"
          />
        </MainPart>
        <MainPart title="Quels sont les symptômes d'une pseudarthrose du scaphoïde ?">
          <div className="flex flex-col">
            <Typography>
              Le scaphoïde, principal os première rangée du carpe, est l&apos;os du carpe le
              plus fracturé (70% des cas). Compte-tenu de sa vascularisation précaire,
              la consolidation osseuse est difficile à obtenir, surtout en cas de tabagisme actif.
            </Typography>
            <Typography weight="bold">
              La pseudarthrose du scaphoïde survient en cas de
              mauvaise prise en charge de la fracture initiale
              {' '}
              <Typography variant="span">(fracture passée inaperçue, immobilisation de courte durée...).</Typography>
            </Typography>
            <Typography>
              La pseudarthrose peut être asymptomatique pendant plusieurs mois voire
              plusieurs années et être révélée à la suite d&apos;un traumatisme bénin.
            </Typography>
            <Typography>
              A l&apos;examen clinique, on retrouve une douleur du bord externe du poignet
              (tabatière anatomique), exacerbée par la compression du pouce ou lors de
              l&apos;appui et de l&apos;extension poignet. On peut également retrouver un
              gonflement du poignet, une diminution de la force de serrage
              et des amplitudes articulaires.
            </Typography>
          </div>
        </MainPart>
        <MainPart title="Quels sont les examens qui confirment une pseudarthrose du scaphoïde ?">
          <List>
            <List.Item>
              <Typography>
                Les radiographies du poignet permettent de visualiser la pseudarthrose
                du scaphoïde et une éventuelle arthrose associée (Figure 2).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure2-pseudarthroseduscaphoide.jpg"
            caption="Figure 2. Pseudarthrose du scaphoïde chez un adolescent. A noter la présence de cartilage de croissance au niveau du radius et de l&apos;ulna."
            currentOpTitle="Pseudoarthrose du scaphoïde"
          />
          <List>
            <List.Item>
              <Typography>
                <a className="underline text-link hover:text-primary" href="https://www.doctissimo.fr/html/sante/imagerie/arthographie.htm" target="_blank" rel="noreferrer">L&apos;arthroscanner</a>
                {' '}
                : un produit de contraste à base d&apos;iode est injecté dans le poignet
                quelques minutes avant la réalisation du scanner (Figure 3). Cet examen
                incontournable est le « gold standard » pour l&apos;analyse complète de
                l&apos;articulation du poignet (os, cartilage et ligaments).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[350px]"
            imageName="figure3-pseudarthroseduscaphoide.jpg"
            caption="Figure 3. Pseudarthrose du scaphoïde avec importante perte de substance osseuse"
            currentOpTitle="Pseudoarthrose du scaphoïde"
          />
          <List>
            <List.Item>
              <Typography>
                IRM avec injection de gadolinium : est indiquée en cas de pseudarthrose proximal
                e ou en cas d&apos;aspect scléreux du scaphoïde, à la recherche d&apos;une
                éventuelle nécrose osseuse.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quels sont les traitements chirurgicaux pour une pseudarthrose du scaphoïde ?">
          <div className="flex flex-col">
            <Typography>
              La prise en charge chirurgicale précoce d&apos;une pseudarthrose du
              scaphoïde est indispensable afin de prévenir la survenue d&apos;arthrose du
              poignet (SNAC Wrist, cf. chapitre spécifique). L&apos;intervention est le
              plus souvent réalisée sous anesthésie loco-régionale (seul le bras est endormi),
              en ambulatoire (pas d&apos;hospitalisation).
            </Typography>
            <Typography>
              Les techniques chirurgicales sont nombreuses, le choix étant fait
              selon la nature de la pseudarthrose et les habitudes du chirurgien.
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography>
                Greffe osseuse non vascularisée : le scaphoïde est abordé par
                une courte incision sur le bord antérieur du poignet (Figure 4).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure4-pseudarthroseduscaphoide.jpg"
            caption="Figure 4. Incision cutanée pour le traitement chirurgical d&apos;une pseudarthrose du scaphoïde"
            currentOpTitle="Pseudoarthrose du scaphoïde"
          />
          <Typography>
            Le tissu cicatriciel de la pseudarthrose est retiré jusqu&apos;à
            retrouver un os saignant,
            de bonne qualité. Une greffe osseuse (prélevée au niveau du poignet ou du bassin)
            est mise en place dans le scaphoïde qui sera ensuite
            stabilisé par deux broches ou une vis.
          </Typography>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure5-pseudarthroseduscaphoide.jpg"
            caption="Figure 5. Consolidation de pseudarthrose du scaphoïde après greffe et stabilisation par deux broches"
            currentOpTitle="Pseudoarthrose du scaphoïde"
          />
          <List>
            <List.Item>
              <Typography>
                Greffe osseuse vascularisée : indiquée en cas de reprise
                chirurgicale ou cas de présence
                de nécrose osseuse préopératoire du scaphoïde. La particularité de cette technique
                est de prélever une greffe osseuse sur le
                bord latéral du poignet, elle-même vascularisée par
                une petite artère (greffon vascularisé selon Zaidemberg). Cette greffe remplacera le
                tissu cicatriciel de la pseudarthrose et sera fixé par deux broches.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Greffe osseuse sous arthroscopie : l&apos;exérèse du
                tissu cicatriciel de la pseudarthrose,
                la mise en place de la greffe et la
                fixation du scaphoïde sont possibles sous arthroscopie.
                La main est installée sur un dispositif de traction (tour de Whipple),
                une caméra et des
                instruments miniaturisés sont ensuite introduits dans le poignet par de petites
                incisions. Il s&apos;agit d&apos;une nouvelle technique ayant de
                nombreux avantages :
                cicatrices plus discrètes, préservation de la vascularisation du scaphoïde
                et diminution de la raideur articulaire post-opératoire.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Implant scaphoïdien en pyrocarbone APSI (Adaptative Proximal Scaphoid Implant) : cet
                implant en indiqué en dernier recours, après échec du traitement conservateur.
                La partie proximale du scaphoïde est retirée et remplacée par cet
                implant (Figure 6).
              </Typography>
            </List.Item>
          </List>
          <FigureDouble
            maxWidth="max-w-[350px]"
            height="h-[150px]"
            imageNameOne="figure6-pseudarthroseduscaphoide.jpg"
            imageNameTwo="figure6bis-pseudarthroseduscaphoide.jpg"
            caption="Figure 6. Implant scaphoïdien en pyrocarbone (APSI) mis en place dans le cadre d&apos;une pseudarthrose proximale du scaphoïde"
            currentOpTitle="Pseudoarthrose du scaphoïde"
          />
        </MainPart>
        <MainPart title="Quelles sont les suites post-opératoires après une opération chirurgicale pour un pseudarthrose du scaphoïde ?">
          <Typography>
            L&apos;arrêt du tabac est indispensable afin d&apos;obtenir une consolidation osseuse.
            Dans les suites post-opératoires,
            des soins de cicatrice sont réalisés tous les deux jours.
            Une manchette en résine est mise en place à J7 afin d&apos;immobiliser le poignet
            sur une période de 6 semaines à 3 mois en fonction de la nature de la pseudarthrose.
            Une surveillance radiologique régulière est nécessaire dans le suivi post-opératoire
            (radiographies standards à J7, J45 et un scanner à 3 mois). Les broches sont
            retirées une fois la consolidation osseuse obtenue (au 3ème mois en général).
            La rééducation est initiée à l&apos;ablation de l&apos;attelle et est poursuivie sur
            une période de 6 à 12 mois. La reprise de sport est possible à 6 mois.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale du pseudarthrose du scaphoïde ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de complications
            secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Perte de mobilité : principale conséquence de la chirurgie du
                poignet, elle peut intéresser la flexion et/ou l&apos;extension du poignet
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Perte de force résiduelle, malgré la consolidation osseuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Problèmes liés au matériel : migration de broche, rupture de matériel (vis, broches)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Absence de consolidation osseuse nécessitant le plus souvent
                une chirurgie de reprise avec greffe osseuse vascularisée
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Lésions iatrogènes (secondaires à la chirurgie) : peuvent intéresser les
                différentes structures de la main (nerf, artère, tendon...). Ces complications
                sont rares entre des mains expertes
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome du site opératoire : nécessitant une reprise chirurgicale
                pour évacuation, uniquement en cas de complication cutanée et/ou neurologique
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Luxation d&apos;implant APSI : rare, mais possible. Elle nécessite le
                plus souvent une reprise chirurgicale pour façonner le poignet afin
                de mieux accueillir l&apos;implant
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default PseudarthroseDuScaphoide;
